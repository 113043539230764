<template>
  <div class="container">
    <div class="row">
      <div class="col-sm-12">
        <div class="w-100 text-right">
          <modal-btn btn-class="btn-sm btn-soft-danger" target="#add-images">
            إضافة&nbsp;<i class="fa fa-plus"></i>
          </modal-btn>
        </div>
        <div class="table-responsive">
          <table class="table">
            <thead>
            <tr class="bg-bb">
              <th>الصورة</th>
              <th>نوع الصورة</th>
              <th>تاريخ التحميل</th>
              <th></th>
            </tr>
            </thead>
            <tbody v-if="hasImages" v-viewer="{}">
            <EditImagesItem
                v-for="image in images"
                :key="image.id"
                :image="image"
                :owner="ownerType"
                @deleted="deleted"
                @updated="updated"
            />
            </tbody>
          </table>
        </div>

        <teleport to="body">
          <confirm-model
              title="إضافة صور"
              target-id="add-images"
              header-bg="bg-soft-danger"
              confirm-text="إضافة"
              cancel-btn="btn-soft-danger"
              @confirmed="store">
            <form id="add-images-form">
              <div class="container">
                <div class="row">
                  <div class="col-sm-12">
                    <label>نوع الصورة</label>
                    <BootstrapSelect
                        id="type"
                        title="نوع الصورة"
                        :options="types"
                        :search="false"
                        :empty-option="false"
                        value_key="value"
                        @changed="setMultiple"
                    />
                  </div>
                </div>
                <div class="row">
                  <div class="col-sm-12">
                    <FormImage
                        ref="images"
                        :shadow="false"
                        id="images"
                        name="images[]"
                        accept=".jpeg,.jpg,.png,.gif,.tif,.bmp,.ico,.psd,.webp"
                        :multiple="multiple"
                    />
                  </div>
                </div>
              </div>
            </form>
          </confirm-model>
        </teleport>
      </div>
    </div>
  </div>
</template>

<script>
import files_mixin from "@/mixins/files_mixin"
import types from "@/common/types"
import EditImagesItem from "@/components/Common/EditImagesItem"
import ModalBtn from "@/components/UI/ModalBtn"
import ConfirmModel from "@/components/UI/ConfirmModel"
import FormImage from "@/components/Forms/FormImage"
import BootstrapSelect from "@/components/Forms/BootstrapSelect";

export default {
  components: {BootstrapSelect, FormImage, ConfirmModel, ModalBtn, EditImagesItem},
  mixins: [files_mixin],
  props: {
    ownerId: {required: true},
    ownerType: {required: true},
    gallery: {default: false},
  },
  data() {
    return {
      types: [],
      images: [],
      selectedImage: '',
      slidId: '',
      selectedTab: '',
      multiple: false
    }
  },
  computed: {
    shouldReload() {
      return this.$store.getters.shouldReload
    },
    hasImages() {
      return !!this.images && this.images.length > 0
    }
  },
  methods: {
    deleted(deletedImage) {
      this.images = this.images.filter(image => {
        return parseInt(deletedImage) !== image.id
      })
    },
    updated(updatedImage) {
      const index = this.images.findIndex(image => {
        return parseInt(updatedImage.id) === parseInt(image.id)
      })

      if (index !== -1) {
        this.images[index] = updatedImage
      }
    },
    resetInputs() {
      this.emptyField('#type')
      this.$refs.images.clearInputFile('images')
    },
    async setModelImages() {
      const formData = new FormData()
      formData.set('owner_type', this.ownerType)
      formData.set('_method', 'GET')
      await this.sendImagesRequest("/get-image", formData)
    },
    addUnique() {
      // if it returned false => no items in images list with same flag
      let type = $_('#type').val()
      type = !!type ? parseInt(type) : ''

      if (this.images.length === 0) {
        return false
      }

      for (const image of this.images) {
        if (types.isObject(image)) {
          return parseInt(image.flag) === type && parseInt(image.flag) !== 2
        }
      }
      return false
    },
    async store() {
      const formData = new FormData($_('#add-images-form')[0])
      formData.set('id', this.ownerId)
      formData.set('owner_type', this.ownerType)

      if (this.addUnique())
        return http.infoMessage('لا يمكن إضافة اكثر من صورة لهذا النوع')

      if (this.fileIsEmpty('images'))
        return http.errorMessage(messages.shouldSelectImg)

      if (!$_('#type').val())
        return http.errorMessage('يرجى تحديد نوع الصورة المضافة')

      // send uploaded images
      await this.sendImagesRequest('/images', formData)
      await this.resetInputs()
      await this.hideModal('#add-images')
      this.fireMessage()
    },
    async sendImagesRequest(url, formData) {
      formData.set('id', this.ownerId)
      const response = await http.send(url, formData, {
        headers: {'Content-type': 'multipart/form-data'}
      })

      if (http.responseAccepted(response)) {
        if (!!response.data.msg)
          http.infoMessage(response.data.msg)
        if (!!response.data.images)
          this.images = response.data.images
        if (!!response.data.types)
          this.types = response.data.types
      }
    },
    setMultiple(value) {
      this.multiple = this.ownerType === 'auction' && parseInt(value) === 2
    }
  },
  async created() {
    await this.setModelImages()
  }
}
</script>

<style scoped>
.carousel-inner {
  min-height: 230px;
}

.imageable {
  min-height: 200px;
  max-height: 450px;
  width: 100%;
}

.gallery-image {
  height: 80px;
}

.gallery-image:hover {
  opacity: 0.5;
  transition: opacity 0.5s;
}

img {
  width: auto;
  height: auto;
  object-fit: cover;
  max-width: 100%;
  max-height: 100%;
}

.carousel-control {
  background-color: rgba(0, 0, 0, 0.3);
}

.carousel-control:hover {
  box-shadow: 0 .5rem 1rem rgba(0, 0, 0, .15);
}
</style>
