<template>
  <div class="container" v-if="!!auctionId">
    <div class="row justify-content-center">
      <div class="col-sm-12 pm-0">
        <EditImages
            owner-type="auction"
            :owner-id="auctionId"
            :gallery="true"
        />
      </div>
    </div>
  </div>
</template>

<script>
import EditImages from "@/components/Common/EditImages";

export default {
  components: {EditImages},
  computed: {
    auctionId() {
      return this.$route.params.auction;
    },
  },
}
</script>

<style scoped>

</style>
