<template>
  <tr v-if="!!image" :class="rowClass">
    <td>
      <div class="symbol symbol-100px symbol-light mr-1 pointer">
        <img :src="imagePath" :alt="image.name">
      </div>
    </td>
    <td>{{ image.name }}</td>
    <td dir="ltr">{{ image.date_time }}</td>
    <td>
      <button class="btn btn-icon btn-sm btn-primary m-1" @click="update">
        <tooltip title="تعديل">
          <i class="fas fa-edit"></i>
        </tooltip>
      </button>

      <button v-if="isSubAuctionImage || isInsuranceUserImage" class="btn btn-icon btn-sm btn-soft-danger m-1" @click="deleteImage">
        <tooltip title="حذف">
          <i class="fas fa-trash"></i>
        </tooltip>
      </button>

      <button v-if="isSubAuctionImage" class="btn btn-icon btn-sm btn-soft-light m-1" @click="setMainAuctionImage">
        <tooltip title="تعيين صورة رئيسية">
          <i class="fas fa-sort-amount-up"></i>
        </tooltip>
      </button>
    </td>
  </tr>
</template>

<script>
import Tooltip from "@/components/UI/Tooltip"
import files_mixin from "@/mixins/files_mixin"

export default {
  name: "EditImagesItem",
  components: {Tooltip},
  emits: ['deleted', 'updated'],
  mixins: [files_mixin],
  props: {
    image: {required: true},
    owner: {required: true},
  },
  computed: {
    isMainAuctionImage() {
      return this.owner === 'auction' && this.image.flag === 1
    },
    isSubAuctionImage() {
      return this.owner === 'auction' && this.image.flag === 2
    },
    isReceiveAuctionImage() {
      return this.owner === 'auction' && this.image.flag === 3
    },
    isAuthAuctionImage() {
      return this.owner === 'auction' && this.image.flag === 4
    },
    isIdUserImage() {
      return this.owner === 'user' && this.image.flag === 1
    },
    isInsuranceUserImage() {
      return this.owner === 'user' && this.image.flag === 2
    },
    mainImageModal() {
      return `main-image-modal-${this.image.id}`
    },
    editImageModal() {
      return `edit-image-${this.image.id}`
    },
    deleteImageModal() {
      return `delete-image-${this.image.id}`
    },
    rowClass() {
      const classes = []
      if (this.isMainAuctionImage || this.isIdUserImage) {
        classes.push('bg-e0')
      } else if (this.isReceiveAuctionImage || this.isAuthAuctionImage || this.isInsuranceUserImage) {
        classes.push('bg-light-blue')
      } else {
        classes.push('bg-light')
      }

      return classes
    },
    imagePath() {
      if (!this.image?.path)
        return '';
      return `${this.$store.getters.publicPath}/storage/${this.image.path}`;
    },
  },
  methods: {
    async setMainAuctionImage() {
      this.fire({
          text: "هل أنت متأكد من تعيين الصورة الحالية صورة رئيسية للمزاد ؟",
        },
        async () => {
          await http.send('/image/auctions/main', {image: this.image.id})
          location.reload()
        })
    },
    async update() {
      this.fire({
        title: 'تعديل صورة',
        input: 'file',
        inputLabel: 'اختر',
        inputAttributes: {accept: 'image/*'},
        confirmButtonText: "تعديل",
        icon: 'info',
        inputValidator: value => {
          if (!value) {
            return 'يرجى اختيار صورة'
          }

          if (!this.isImage(value)) {
            return 'يرجى اختيار ملف من نوع صورة'
          }
        }
      }, async (result) => {
        if (!result.isConfirmed || !result.value) {
          return
        }

        // Set image by form data
        const formData = new FormData()
        formData.set('_method', 'PUT')
        formData.set('file', result.value)

        const response = await http.send(`/images/${this.image.id}`, formData, {
          headers: {'Content-Type': 'multipart/form-data'}
        })

        if (http.responseAccepted(response)) {
          this.$emit('updated', response.data.image)
          this.hideModal(`#${this.editImageModal}`)
          this.fireMessage()
        }
      })

    },
    async deleteImage() {
      this.fire({
        text: "هل أنت متأكد من حذف الصورة ؟",
        icon: 'error'
      }, async () => {
        // Delete Image
        await http.send(`/images/${this.image.id}`, {_method: 'DELETE'})
        this.$emit('deleted', this.image.id)
      })
    }
  }
}
</script>